import React from 'react';

const ClientDetails = ({ client }) => {
    return (
        <tr key={client.payout_item_id}>
            <td className="p-2 text-center">{client.payout_item.sender_item_id}</td>
            <td className="p-2 text-center">{client.payout_item.receiver}</td>
            <td className="p-2 text-center">{client.payout_item.amount.value} {client.payout_item.amount.currency}</td>
            <td className="p-2 text-center">{client.payout_item.recipient_wallet}</td>
            <td className="p-2 text-center">{client.payout_item.recipient_type}</td>
            <td className="p-2 text-center">{client.transaction_status}</td>
        </tr>
    );
};

export default ClientDetails;
