import React from 'react';

const ClientPreview = ({ client, number }) => {

    return (
        <tr key={client.sender_item_id}>
            <td className="p-2 text-center">{number}</td>
            <td className="p-2 text-center">{client.sender_item_id}</td>
            <td className="p-2 text-center">{client.receiver}</td>
            <td className="p-2 text-center">{client.recipient_wallet}</td>
            <td className="p-2 text-center">{client.amount.value} {client.amount.currency}</td>
        </tr>
    );
};

export default ClientPreview;
