import React, { useState } from 'react';
import Clients from './components/Clients';
import Login from "./components/Login";

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    return (
        <div>
            <Clients />
            {/*{isLoggedIn ? <Clients /> : <Login setAuthenticated={(loggedIn)=>{ setIsLoggedIn(loggedIn) } } />}*/}
        </div>
    );
};

export default App;
