import React, { useState, useEffect, useRef } from 'react';
import ClientDetails from './ClientDetails';
import ClientPreview from './ClientPreview';
import Loading from "./Loading";
import {BiRefresh} from 'react-icons/bi'

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [batchHeader, setBatchHeader] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPayoutSent, setIsPayoutSent] = useState(false);
    const [totalClients, setTotalClients] = useState(0);
    const [totalPayout, setTotalPayout] = useState(0);

    const FETCH_URL = 'http://localhost:5000';
    // const FETCH_URL = 'https://paypal-payout-server.onrender.com';


    const payoutRef = useRef();

    useEffect(() => {
        fetchClients(); // Fetch clients when the component is mounted
    }, [setClients, setTotalClients]);

// Watch for changes in the clients array and update the button's disabled state
/*    useEffect(() => {
        if (clients.length === 0) {
            payoutRef.current.disabled = true;
            payoutRef.current.style.cursor = "not-allowed";
        } else {
            payoutRef.current.disabled = false;
            payoutRef.current.style.cursor = "pointer";
        }
    }, [clients]);*/

    // Function to fetch the list of users (clients)
    const fetchClients = async () => {
        setIsLoading(true); // Show loading element
        console.log("fetching clients")
        try {
            const URL = FETCH_URL+'/get-users';


            const response = await fetch(URL); // Change DEVELOPMENT_URL+the endpoint to fetch us'rs
            const data = await response.json();
            setClients(data); // Set the list of clients
            setTotalClients(data.length);

            // Calculate the total payout whenever clients change
            const calculatedTotal = calculateTotalPayout();
            setTotalPayout(calculatedTotal);

            setIsLoading(false); // hide loading element
            console.log("clients fetched")
        } catch (error) {
            console.error('Error fetching clients:', error);

            document.addEventListener("DOMContentLoaded", function(event) {
                payoutRef.current.disabled.disabled = true;
            });

        }
    };

    const refreshClients = async ()=>{

        await fetchClients();
    }

    // Function to handle the payout request
    const handleSendPayout = async () => {

        if( window.confirm("Are you sure you want to send the payouts?") ){

            setIsLoading(true); // Show loading element
            const URL = FETCH_URL + '/process-payment';
            try {

                console.log(`fetching from ${URL}`)
                const response = await fetch(URL, {
                    method: 'POST', body: JSON.stringify({
                        body : 'make payouts'
                    })
                });
                const data = await response.json();

                console.log(data);
                if (data.debug_id) {
                    setError({
                        name: data.name,
                        message: data.message,
                    });

                    console.log('error ' + data.name )
                    setIsLoading(false); // Hide loading element after data is fetched

                } else {

                    console.log('success ' + data.items)
                    setBatchHeader(data.batch_header);
                    setClients(data.items);
                    setIsPayoutSent(true); // Set the isPayoutSent state to true to conditionally render the table or error message
                    setIsLoading(false); // Hide loading element after data is fetched

                }

            } catch (error) {
                console.error('Error fetching payout data:', error);
                setError(error.message);
            }

            setIsLoading(false); // Hide loading element after data is fetched

        }

    };


    // Function to format the date with time
    const formatDateTime = (dateString) => {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    function calculateTotalPayout() {

        let total = 0;
        clients.forEach((client) => {
            total += parseFloat(client.amount.value);
        });
        return total;

    }

    return (
        <div className="max-w-5xl mx-auto p-4">
            {isLoading ? ( // Show loading element when isLoading is true
                <Loading/>
            ) : isPayoutSent ? (
                <>
                    {error ? (
                        <div className="bg-red-200 p-4 rounded">
                            <p>Error name: {error.name}</p>
                            <p>Error message: {error.message}</p>
                        </div>
                    ) : (
                        <>
                            {batchHeader && (
                                <>
                                    <h1 className="text-3xl font-bold mb-2">Payment Description</h1>
                                    <table className="w-full border-collapse border mb-10">
                                        <thead>
                                        <tr className="bg-gray-200">
                                            <th className="p-2 text-center">Batch ID</th>
                                            <th className="p-2 text-center">Batch Status</th>
                                            <th className="p-2 text-center">Time Created</th>
                                            <th className="p-2 text-center">Email Subject</th>
                                            <th className="p-2 text-center">Email Message</th>
                                            <th className="p-2 text-center">Amount Paid</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="p-2 text-center">{batchHeader.payout_batch_id}</td>
                                            <td className="p-2 text-center">{batchHeader.batch_status}</td>
                                            <td className="p-2 text-center">
                                                {formatDateTime(batchHeader.time_created)}
                                            </td>                                    <td className="p-2 text-center">{batchHeader.sender_batch_header.email_subject}</td>
                                            <td className="p-2 text-center">{batchHeader.sender_batch_header.email_message}</td>
                                            <td className="p-2 text-center">
                                                {batchHeader.amount.value} {batchHeader.amount.currency}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </>
                            )}

                            <h1 className="text-3xl font-bold mb-2">Client Payment Description</h1>
                            <table className="w-full border-collapse border">
                                <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-2 text-center">Name</th>
                                    <th className="p-2 text-center">Email</th>
                                    <th className="p-2 text-center">Amount</th>
                                    <th className="p-2 text-center">Wallet</th>
                                    <th className="p-2 text-center">Recipient Type</th>
                                    <th className="p-2 text-center">Payment Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {clients && clients.map((client) => (
                                    <ClientDetails key={client.payout_item_id} client={client} />
                                ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </>
            ) : (
                <div className="flex-col justify-center items-center h-40 space-y-10">


                    <h1 className="text-3xl font-bold mb-2">Client Payment Description</h1>
                    <table className="w-full border-collapse border">
                        <thead>
                        <tr className="bg-gray-200">
                            <th className="p-2 text-center"># ({totalClients} clients)</th>
                            <th className="p-2 text-center">Name</th>
                            <th className="p-2 text-center">Email</th>
                            <th className="p-2 text-center">Wallet</th>
                            <th className="p-2 text-center">Amount to be Paid (${totalPayout})</th>
                        </tr>
                        </thead>
                        <tbody>
                        {clients && clients.map((client, index) => {
                            return(
                            <ClientPreview key={client.sender_item_id} client={client} number={index+1} />
                        )})}
                        </tbody>
                    </table>

                    <div className={'flex justify-center items-center'}>
                        <button
                            ref={payoutRef}
                            title={'send payouts'}
                            onClick={handleSendPayout}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto"
                        >
                            Send Payout
                        </button>
                        <button
                            title={'refresh clients'}
                            onClick={refreshClients}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mx-auto"
                        >
                            <BiRefresh/>
                        </button>
                    </div>

                </div>

            )}
        </div>
    );
};

export default Clients;
